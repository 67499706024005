<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab === 'InspectionTaskPanel'">
        <InspectionTaskPanel />
      </div>
      <div v-if="currentTab === 'InspectionRecordPanel'">
        <InspectionRecordPanel />
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    InspectionTaskPanel: () => import("./InspectionTaskPanel"),
    InspectionRecordPanel: () => import("./InspectionRecordPanel"),
  },
  data() {
    return {
      tabList: [
        {
          key: "InspectionTaskPanel",
          tab: "点检保养任务",
        },
        {
          key: "InspectionRecordPanel",
          tab: "点检保养记录",
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
